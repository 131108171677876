<template>
  <div>
    <v-app-bar
      elevation="0"
      :hide-on-scroll="hideOnScroll"
      app
      dense
      style="z-index: 10;"
      v-bind:color="color"
      v-model="isMenuOpen"
      v-if="$vuetify.breakpoint.smAndUp"
      v-bind:class="{'rewrite-height': heightAppBar, 'closed': !isMenuOpen}"
    >
      <!-- v-bind:height="heightAppBar" -->
      <v-container fluid style="height: 100%;">
        <v-row style="height: 100%;" no-gutters>
          <v-col offset="3" cols="6" class="py-0" align-self="center">
            <template v-if="isMenuOpen">

              <template v-if="$route.name == 'Home' ">
                <template>
                  <v-img
                    alt="Marisol Logo"
                    class=" mx-auto logo-header-new logo-header-transition"
                    contain
                    transition="scale-transition"
                    :src="logoBlackMarisol"
                    :class="{ 'rewrite-width-logo': logo_1_rewrite_width }"
                  >
                    <router-link to="/" class="d-block" style="height: 100%; width: 100%" v-if="$route.name != 'Home'"></router-link>
                  </v-img>

                  <v-img
                    contain
                    class="mt-2 mx-auto logo-2-malibu d-block logo-header-transition"
                    :src="logoBlackMalibu"
                    :class="{ 'rewrite-width-logo': logo_1_rewrite_width }"
                  ></v-img>

                </template>
              </template>
              <template v-else>

                <template v-if="$route.name == 'Privacy' || $route.name == 'Access' || $route.name == 'TourInfo'">
                    <template v-if="!hideOnScroll">
                    <v-img
                      alt="Marisol Logo"
                      class=" mx-auto logo-header-new logo-header-transition"
                      contain
                      transition="scale-transition"
                      :src="logoBlackMarisol"
                      :class="{ 'rewrite-width-logo': logo_1_rewrite_width }"
                      v-if="!hideOnScroll"
                    >
                      <router-link to="/" class="d-block" style="height: 100%; width: 100%" v-if="$route.name != 'Home'"></router-link>
                    </v-img>

                    <v-img
                      contain
                      class="mt-2 mx-auto logo-2-malibu d-block logo-header-transition"
                      :src="logoBlackMalibu"
                      :class="{ 'rewrite-width-logo': logo_1_rewrite_width }"
                      v-if="!hideOnScroll"
                    ></v-img>
                  </template>
                  <template v-else>
                    <v-img
                      alt="Marisol Logo"
                      class=" mx-auto logo-header-new logo-header-transition"
                      contain
                      transition="scale-transition"
                      :src="logoBlackMarisol"
                      :class="{ 'rewrite-width-logo': logo_1_rewrite_width }"
                      v-if="hideOnScroll"
                    >
                      <router-link to="/" class="d-block" style="height: 100%; width: 100%" v-if="$route.name != 'Home'"></router-link>
                    </v-img>

                    <v-img
                      contain
                      class="mt-2 mx-auto logo-2-malibu d-block logo-header-transition"
                      :src="logoBlackMalibu"
                      :class="{ 'rewrite-width-logo': logo_1_rewrite_width }"
                      v-if="hideOnScroll"
                    ></v-img>
                  </template>
                </template>
                <template v-else>

                  <template v-if="!hideOnScroll">
                    <v-img
                      alt="Marisol Logo"
                      class=" mx-auto logo-header-new logo-header-transition"
                      contain
                      transition="scale-transition"
                      :src="logoWhiteMarisol"
                      :class="{ 'rewrite-width-logo': logo_1_rewrite_width }"
                    >
                      <router-link to="/" class="d-block" style="height: 100%; width: 100%" v-if="$route.name != 'Home'"></router-link>
                    </v-img>

                    <v-img
                      contain
                      class="mt-2 mx-auto logo-2-malibu d-block logo-header-transition"
                      :src="logoWhiteMalibu"
                      :class="{ 'rewrite-width-logo': logo_1_rewrite_width }"
                    ></v-img>
                  </template>
                  <template v-else>
                    <v-img
                      alt="Marisol Logo"
                      class=" mx-auto logo-header-new logo-header-transition"
                      contain
                      transition="scale-transition"
                      :src="logoBlackMarisol"
                      :class="{ 'rewrite-width-logo': logo_1_rewrite_width }"
                    >
                      <router-link to="/" class="d-block" style="height: 100%; width: 100%" v-if="$route.name != 'Home'"></router-link>
                    </v-img>

                    <v-img
                      contain
                      class="mt-2 mx-auto logo-2-malibu d-block logo-header-transition"
                      :src="logoBlackMalibu"
                      :class="{ 'rewrite-width-logo': logo_1_rewrite_width }"
                    ></v-img>
                  </template>
                </template>




              </template>
            
            </template>
          </v-col>
          <v-col cols="3" class="text-right px-0 py-0">
            <template v-if="isMenuOpen">
              <template v-if="$route.name == 'Home'">
                <v-btn
                  v-if="showBarMobile"
                  v-bind:ripple="false"
                  @click="$emit('open')"
                  text
                  classsss="letter-spacing-0 not-text-transform btn-menu-div animation"
                  style="animation-delay: 2s"
                >
                  <span
                    class="continuos-texts"
                    :style="'color:' + colorText + '; transition: color .5s;'"
                    style="text-transform: none"
                    :class="{ 'black--text': updateInfo == 0 || !logoColor }"
                  >
                    Menu
                  </span>
                </v-btn>
              </template>
              <template v-else>

                <template v-if="$route.name == 'Privacy' || $route.name == 'Access' || $route.name == 'TourInfo'">
                    <v-btn
                      v-if="showBarMobile"
                      v-bind:ripple="false"
                      @click="$emit('open')"
                      text
                      classsss="letter-spacing-0 not-text-transform btn-menu-div animation"
                      style="animation-delay: 2s"
                    >
                      <span
                        class="continuos-texts"
                        :style="'color:' + colorText + '; transition: color .5s;'"
                        style="text-transform: none"
                        :class="{ 'black--text': updateInfo == 0 || !logoColor }"
                      >
                        Menu
                      </span>
                    </v-btn>
                </template>
                <template v-else>
                  <template v-if="!hideOnScroll">
                    <v-btn
                      v-if="showBarMobile"
                      v-bind:ripple="false"
                      @click="$emit('open')"
                      text
                      classsss="letter-spacing-0 not-text-transform btn-menu-div animation"
                      style="animation-delay: 2s"
                    >
                      <span
                        class="continuos-texts"
                        :style="'color:' + colorText + '; transition: color .5s;'"
                        style="text-transform: none"
                      >
                        Menu
                      </span>
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn
                      v-if="showBarMobile"
                      v-bind:ripple="false"
                      @click="$emit('open')"
                      text
                      classsss="letter-spacing-0 not-text-transform btn-menu-div animation"
                      style="animation-delay: 2s"
                    >
                      <span
                        class="continuos-texts black--text"
                        :style="'color:' + colorText + '; transition: color .5s;'"
                        style="text-transform: none"
                      >
                        Menu
                      </span>
                    </v-btn>
                  </template>
                </template>


              </template>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <!-- <div class="d-none d-sm-block">
      <v-btn
        v-if="showBarMobile"
        v-bind:ripple="false"
        @click="$emit('open')"
        text
        class="letter-spacing-0 not-text-transform btn-menu-div animation"
        style="animation-delay: 2s; position: absolute;"
      >
        <span
          class="continuos-texts"
          :style="'color:' + colorText + '; transition: color .5s;'"
          :class="{'black--text': updateInfo == 0}"
        >
          Menu
        </span>
      </v-btn>
    </div> -->

    <div class="bar-mobile" :class="{ 'show-bar': showBarMobile == false }">
      <v-row no-gutters class="fill-height" align="center" justify="center">
        <transition name="fade-slow-transition-2">
          <v-col cols="auto" v-if="showBarMobile == false">
            <v-btn
              @click="$emit('open')"
              :ripple="false"
              text
              class="letter-spacing-0 not-text-transform"
            >
              <span class="continuos-texts">Menu</span>
            </v-btn>
          </v-col>
        </transition>
      </v-row>
    </div>

    <!-- Menú -->
    <Menu :visible="menu_open" @close="$emit('close')"></Menu>
    <!-- /Menú -->
  </div>
</template>

<script>
import Menu from "./Menu";
import logoWhiteMarisol from "@/assets/icons/MariSolMalibu_logo.svg";
import logoBlackMarisol from "@/assets/icons/MariSolMalibu_logo-NEGRO.svg";
import logoWhiteMalibu from "@/assets/icons/MALIBU_logo.svg";
import logoBlackMalibu from "@/assets/icons/MALIBU_logo-NEGRO.svg";

export default {
  name: "Header",
  components: {
    Menu,
  },
  props: {
    updateInfo: { type: Number, required: false },
    menu_open: { type: Boolean, required: false },
  },
  data() {
    return {
      flag: false,
      open_menu: false,
      colorText: "white",
      showBarMobile: true,
      innerWidth: 0,
      isMenuOpen: true,
      scroll: 0,
      color: "transparent",
      heightAppBar: false,
      logo_1_rewrite_width: false,
      logoWhiteMarisol,
      logoBlackMarisol,
      logoWhiteMalibu,
      logoBlackMalibu,
      logoColor: 'false',
    };
  },
  computed: {
    hideOnScroll: function () {

      if(this.$route.name == 'Home'){

        if(this.flag){

          let bloque = document.querySelector('#home__secondBlock').getBoundingClientRect();
          

          if(bloque.top < 0){
            return true
          }else{
            return false
          }
        }
          
      }


      if( this.scroll < this.$vuetify.breakpoint.height){
        return false
      }else{
        return true
      }
    }
  },
  watch: {
    $route() {
      this.flag = false;
      this.color = "transparent"
      setTimeout(() => {
        
        this.changeOnScroll();
      }, 200);
      this.$emit("close");
    },
    scroll: function () {
      if (this.scroll < 100) {
        // this.color = "transparent";
        this.logo_1_rewrite_width = false;
        this.heightAppBar = false
        this.logoColor = true

        // if (
        //   this.$vuetify.breakpoint.width < 3630 &&
        //   this.$vuetify.breakpoint.width > 2300
        // ) {
        //   this.heightAppBar = 339;
        // } else if (
        //   this.$vuetify.breakpoint.width > 1903 &&
        //   this.$vuetify.breakpoint.width < 2300
        // ) {
        //   this.heightAppBar = 247;
        // } else if (
        //   this.$vuetify.breakpoint.width > 1263 &&
        //   this.$vuetify.breakpoint.width < 1903
        // ) {
        //   this.heightAppBar = 184;
        // } else if (
        //   this.$vuetify.breakpoint.width > 959 &&
        //   this.$vuetify.breakpoint.width < 1263
        // ) {
        //   this.heightAppBar = 168;
        // } else if (
        //   this.$vuetify.breakpoint.width > 599 &&
        //   this.$vuetify.breakpoint.width < 959
        // ) {
        //   this.heightAppBar = 177;
        // }
      } else {
        // this.color = "white";
        this.logo_1_rewrite_width = true;
        this.heightAppBar = true
        this.logoColor = false
      }


      if(this.$route.name == 'Home'){

        
        let bloque = document.querySelector('#home__secondBlock').getBoundingClientRect();

        if(bloque.top < 0){
          this.color = 'white'
        }else{
          this.color = 'transparent'
        }
      }else{

        if(this.$route.name == 'Privacy' || this.$route.name == 'Access' || this.$route.name == 'TourInfo'){
          this.color = '#ece7e1'
        }else{

          if(this.scroll < this.$vuetify.breakpoint.height){
            this.color = 'transparent'
          }else{
            this.color = 'white'
          }
        }
      }
      
    },
    menu_open: function (val) {
      if (val) {
        this.isMenuOpen = false;
      } else {
        this.isMenuOpen = true;
      }
    },
    updateInfo: function(val) {
      if (val == 0) {
        this.logoColor = false
      } else {
        this.logoColor = true
      }
    }
  },
  mounted() {
    //resize about height carousel
    setTimeout(() => {
      this.flag = true;
    }, 1000);
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });

    window.addEventListener("scroll", this.changeOnScroll, { passive: true });

    window.addEventListener("scroll", this.mobileChangeScroll, { passive: true });
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    onResize() {
      // var innerWidth = window.innerWidth;

      // this.heightAppBar = 453

      // // height del appbar
      // if (innerWidth < 3630 && innerWidth > 2300) {
      //     this.heightAppBar = 339
      //   } else if (innerWidth > 1903 && innerWidth < 2300){
      //     this.heightAppBar = 247
      //   } else if (innerWidth > 1263 && innerWidth < 1903){
      //     this.heightAppBar = 184
      //   } else if (innerWidth > 959 && innerWidth < 1263){
      //     this.heightAppBar = 168
      //   } else if (innerWidth > 599 && innerWidth < 959){
      //     this.heightAppBar = 177
      //   }

      this.innerWidth = window.innerWidth;
    },
    changeOnScroll: function () {
      var blockSecond = document.querySelector(".block-second").offsetTop;
      var firstImage = document.querySelector("#firstImage");
      var offset = window.pageYOffset;

      this.scroll = offset;

      try {
        if (firstImage || blockSecond) {
          if (offset < blockSecond) {
            this.colorText = "white";
            firstImage.style.opacity = 0;
          } else {
            this.colorText = "black";
            firstImage.style.opacity = 1;
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    },
    mobileChangeScroll: function () {
      if (document.documentElement.scrollTop > 150 && this.innerWidth <= 600) {
        this.showBarMobile = false;
      } else {
        this.showBarMobile = true;
      }
    },
  },
};
</script>