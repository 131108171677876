import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    children: [
      {
        path: 'book-a-tour',
        name: 'Tour',
        component: () => import('../views/Tour.vue'),
      }
    ]
  },
  {
    path: '/zero1',
    name: 'Property1',
    component: () => import('../views/Property.vue')
  },
  {
    path: '/zero2',
    name: 'Property2',
    component: () => import('../views/Property2.vue')
  },
  // {
  //   path: '/zero2',
  //   name: 'Property2',
  //   component: () => import('../views/Zero2.vue')
  // },
  {
    path: '/zero3',
    name: 'Property3',
    component: () => import('../views/Zero3.vue')
  },
  {
    path: '/zero4',
    name: 'Property4',
    component: () => import('../views/Zero3.vue')
  },
  {
    path: '/net-zero',
    name: 'NetZero',
    component: () => import('../views/NetZero.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/access',
    name: 'Access',
    component: () => import('../views/Access.vue')
  },
  {
    path: '/tour-info',
    name: 'TourInfo',
    component: () => import('../views/TourInfo.vue')
  },
  {
    path: '/11865-ellice-st-malibu-lot/',
    name: 'Property',
    component: () => import('../views/Property.vue')
  },
  { path: '*', redirect: '/' },  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    if(to.name == 'Contact' && from.name == 'Tour'){
      scrollTo = savedPosition.y
    }

    if(to.name == 'Tour'){
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
})

export default router
