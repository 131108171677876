<template>
  <v-app>

    

    
    <Header :updateInfo="menu" :menu_open="menu_open" @open="open" @close="close"></Header>

    <v-main>

      <router-view @update="actualizar" @open="open" @close="close"></router-view>
    </v-main>

    <Footer></Footer>

    <div class="bottom-consent" v-bind:class="{'open':cookieConsent && showCookieConsent}">

      <v-container fluid class="my-0 py-4">
        <v-row no-gutters align="center">
          <v-col cols="10" offset="1" class="text-center">
            <span class="black--text subtitles-big d-block founders-grotesk-light animation line-height-title-intro">Cookie Consent</span>
          </v-col>
          <v-col cols="1" class="text-right">
            <v-btn color="black" icon @click="cookieConsent = false; " onclick="allConsentGranted();">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters class="my-0">
          <v-col>
            <v-container class="my-0 py-0">
              <v-row no-gutters class="my-0" justify="center">
                <v-col class="my-0" style="max-width: 800px">
                  <p class="continuos-texts block__fourth__margin-p line-height-description pre-line mt-3 mb-3">Cookies allow us to improve your experience on our site. By continuing to browse, you agree to the storing of cookies on your device. To learn more about the cookies we use and how we use them, please see the “Use of Cookies” section in our privacy policy.</p>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="my-0 py-0 mt-md-3" justify="center">
          <v-col cols="auto" class="py-0">
            <v-btn color="black" elevation="0" tile width="219px" v-if="!$vuetify.breakpoint.xs" @click="cookieConsent = false; dontShowAgain();" onclick="allConsentGranted();">
              <span class="white--text continuos-texts" style="text-transform: none;">Don't show again</span>
            </v-btn>

            <v-btn color="black" elevation="0" tile width="150px" v-if="$vuetify.breakpoint.xs" @click="cookieConsent = false; dontShowAgain();" onclick="allConsentGranted();">
              <span class="white--text continuos-texts" style="text-transform: none;">Don't show again</span>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="py-0">
            <v-btn color="black" elevation="0" tile width="219px" to="/privacy"  v-if="!$vuetify.breakpoint.xs"> 
              <span class="white--text continuos-texts" style="text-transform: none;">More information</span>
            </v-btn>

            <v-btn color="black" elevation="0" tile width="150px" to="/privacy"  v-if="$vuetify.breakpoint.xs"> 
              <span class="white--text continuos-texts" style="text-transform: none;">More information</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- <v-bottom-sheet v-model="cookieConsent" hide-overlay app :inset="false" :retain-focus="false">
      <v-sheet
        class="text-center"
        height="250px"
        elevation="0"
        color="#ece7e1"
      >
        <v-container fluid class="my-0 py-4">
          <v-row no-gutters align="center">
            <v-col cols="10" offset="1">
              <span class="black--text subtitles-big d-block founders-grotesk-light animation line-height-title-intro">Cookie Consent</span>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn color="black" icon @click="cookieConsent = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters class="my-0">
            <v-col>
              <v-container class="my-0 py-0">
                <v-row no-gutters class="my-0" justify="center">
                  <v-col class="my-0" style="max-width: 800px">
                    <p class="continuos-texts block__fourth__margin-p line-height-description pre-line mt-3">Cookies allow us to improve your experience on our site. By continuing to browse, you agree to the storing of cookies on your device. To learn more about the cookies we use and how we use them, please see the “Use of Cookies” section in our privacy policy.</p>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row class="my-0 py-0" justify="center">
            <v-col cols="auto" class="py-0">
              <v-btn color="black" elevation="0" tile width="219px">
                <span class="white--text continuos-texts" style="text-transform: none;">Don't show again</span>
              </v-btn>
            </v-col>
            <v-col cols="auto" class="py-0">
              <v-btn color="black" elevation="0" tile width="219px" to="/privacy"> 
                <span class="white--text continuos-texts" style="text-transform: none;">More information</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-bottom-sheet> -->

    <!-- <v-bottom-navigation
      color="primary"
      fixed
      height="150"
      v-model="cookieConsent"
      :input-value="false"
    >
      
    </v-bottom-navigation> -->

    <!-- <transition name="fade-transition">
      <div class="btn-back-to-top column-flex text-center line-height-title-2 cursor-pointer" @click="$vuetify.goTo(0)" v-if="showBtn">
        <div>
          <v-img src="@/assets/icons/Flecha-izquierda.svg" class="arrow--to-top"></v-img>
        </div>
      </div>
    </transition> -->
  </v-app>
</template>

<script>
import Vue from 'vue'
import VueSession from 'vue-session'
Vue.use(VueSession,{persist: true})
import Header from "./components/statics/Header";
import Footer from "./components/statics/Footer";

export default {
  name: "App",

  components: {
    Header,
    Footer,
  },

  data: () => ({
    cookieConsent: false,
    showBtn: false,
    menu: 0,
    menu_open: false,
  }),
  computed: {
    showCookieConsent: function () {
      return !this.$session.has('dontShow')
    },
  },
  created() {
    window.addEventListener("scroll", this.showInScroll, {passive:true});
  },
  mounted: function(){
    setTimeout(() => {
      this.cookieConsent = true;
    }, 5000);
  },
  methods: {
    actualizar(x) {
      this.menu = x;
    },
    open(){
      this.menu_open = true
    },
    close(){
      this.menu_open = false
    },
    dontShowAgain: function () {
      this.$session.set('dontShow',true)

      window.consentGrantedAdStorage();
    },
    showInScroll: function () {
      var animate = document.querySelectorAll(".animation-each");
      let scrollTop = document.documentElement.scrollTop;

      for (let index = 0; index < animate.length; index++) {
        const element = animate[index];
        let heightAnimate = element.offsetTop;

        if (heightAnimate - 700 < scrollTop) {
          if (element.classList.contains("fade-up")) {
            element.classList.add("translateElement");
          } else {
            element.style.opacity = 1;
          }
        } else{
          element.style.opacity = 0;
        }
      }

      //código para mostrar botón ir arriba
      if (document.documentElement.scrollTop > 150){
        this.showBtn = true
      }else {
        this.showBtn = false
      }
    },
  },
};
</script>
