<template>
  <v-navigation-drawer v-model="visible" fixed right temporary floating class="menu-big" overlay-color="transparent">
    <transition name="fade-slow-transition-2">
      <v-row
        no-gutters
        class="fill-height"
        justify="center"
        align="start"
        align-sm="center"
        align-md="start"
        v-if="visible"
      >
        <v-col cols="auto" sm="auto" md="12">
          <v-row no-gutters>
            <v-col class="position-relative">
              <div
                @mouseenter="icon_change = true"
                style="width: fit-content"
                class="div-logo"
              >
                <router-link to="/" class="d-none d-sm-inline">
                  <v-img
                    src="@/assets/icons/marisol-icono-estatico.png"
                    class="menu__img logo-visible"
                    contain
                    style="mix-blend-mode: multiply; transition: opacity 5s ease"
                    :class="{
                      'opacity-1': !icon_change,
                      'opacity-0': icon_change,
                    }"
                  ></v-img>
                  <v-img
                    src="@/assets/icons/MARISOL-monograma.gif"
                    class="menu__img logo-visible absolute-element"
                    contain
                    style="
                      mix-blend-mode: multiply;
                      transition: opacity 5s ease;
                      margin-top: 0;
                    "
                    :class="{
                      'opacity-1': icon_change,
                      'opacity-0': !icon_change,
                    }"
                  ></v-img>
                </router-link>
                <router-link to="/" class="d-sm-none">
                  <v-img
                    src="@/assets/img/_MARISOL--icono-2.gif"
                    class="menu__img logo-visible"
                    contain
                    style=" transition: opacity 5s ease"
                    :class="{
                      'opacity-1': !icon_change,
                      'opacity-0': icon_change,
                    }"
                  ></v-img>
                  <v-img
                    src="@/assets/img/_MARISOL--icono-2.gif"
                    class="menu__img logo-visible absolute-element"
                    contain
                    style="

                      transition: opacity 5s ease;
                      margin-top: 0;
                    "
                    :class="{
                      'opacity-1': icon_change,
                      'opacity-0': !icon_change,
                    }"
                  ></v-img>
                </router-link>
              </div>

              <v-btn
                @click="closeMenu"
                :ripple="false"
                text
                class=" letter-spacing-0 not-text-transform btn-close d-none d-md-block"
                style=""
              >
                <span class="continuos-texts black--text"> Close </span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="row-custom" no-gutters>
            <v-col class="col-custom text-center text-md-left mt-10">
              <div>
                <span class="xsmall-texts">Zero Series Properties</span>
              </div>
              <div class="line-height-links">
                <!-- <span class="xsmall-texts btn-menu-link d-block">Available</span> -->
                
                <span class="xsmall-texts btn-menu-link d-block">Coming soon</span>
                <router-link to="/zero3" class="btn-menu-link not-text-decoration "
                  ><span>Zero 3</span>
                  <img src="@/assets/icons/primerLink.svg" alt="" />
                  <!-- <img src="@/assets/icons/tercerLink.svg" alt="" /> -->
                </router-link>
                <router-link to="/zero4" class="btn-menu-link not-text-decoration"
                  ><span>Zero 4</span>
                  <img src="@/assets/icons/primerLink.svg" alt="" />
                  <!-- <img src="@/assets/icons/cuartoLink.svg" alt="" /> -->
                </router-link>
                <span class="xsmall-texts btn-menu-link d-block mt-0">Sold</span>
                <router-link
                  to="/zero1"
                  class="btn-menu-link not-text-decoration mt-1"
                  @click.native="visible = false"
                  ><span>Zero 1</span>
                  <img src="@/assets/icons/primerLink.svg" alt="" />
                </router-link>
                <router-link
                  to="/zero2"
                  class="btn-menu-link not-text-decoration mt-0"
                  @click.native="visible = false"
                  ><span>Zero 2</span>
                  <img src="@/assets/icons/primerLink.svg" alt="" />
                  <!-- <img src="@/assets/icons/segundoLink.svg" alt="" /> -->
                </router-link>

                <h2 class="mt-0">
                  <router-link
                    to="/net-zero"
                    class="btn-menu-link not-text-decoration"
                    @click.native="visible = false"
                    ><span>Net Zero</span></router-link
                  >
                </h2>
                <h2 class="mt-0">
                  <router-link
                    to="/contact"
                    class="btn-menu-link not-text-decoration mt-0"
                    @click.native="visible = false"
                    ><span>Contact</span></router-link
                  >
                </h2>

                <v-btn
                  @click="closeMenu"
                  :ripple="false"
                  text
                  class="letter-spacing-0 not-text-transform btn-close d-md-none"
                  style=""
                >
                  <span class="continuos-texts black--text"> Close </span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </transition>
  </v-navigation-drawer>
</template>
<script>
export default {
  props: { visible: { type: Boolean, required: false } },
  data() {
    return {
      icon_change: false,
    };
  },
  watch: {
    icon_change: function (val) {
      if (val) {
        return setTimeout(() => {
          this.icon_change = false;
        }, 3000);
      }
    },
    visible: function() {
      if(!this.visible){
        this.$emit("close");
      }
    }

  },
  methods: {
    closeMenu: function () {
      return this.$emit("close");
    },
  },
};
</script>