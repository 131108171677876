<template>
    <v-footer class="footer" color="#000000">
        <v-container fluid class="py-0">
            <v-row>
                <v-col class="text-center mb-6">
                    <v-btn dark icon href="https://www.instagram.com/marisolmalibu/" target="_blank"><v-icon>mdi-instagram</v-icon></v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" sm="4" class="text-center" order="1" order-sm="0">
                   <v-row no-gutters class="fill-height" align="start">
                        <v-col class="translate-column">
                            <span class="white--text continuos-texts">Copyright 2024</span>
                        </v-col>
                   </v-row>
                </v-col>
                <v-col cols="12" sm="4" class="text-center" order="0">
                    <template v-if="$route.name != 'Home'">
                        <router-link to="/">
                                <v-img src="@/assets/icons/MariSolMalibu_logo_primary_white.svg" class="footer__icon d-block mx-auto" contain></v-img>
                        </router-link>
                    </template>
                    <template v-else>
                        <v-img src="@/assets/icons/MariSolMalibu_logo_primary_white.svg" class="footer__icon d-block mx-auto cursor-pointer" contain @click="$vuetify.goTo(0)"></v-img>
                    </template>
                </v-col>
                <v-col cols="12" sm="4" class="text-center footer__my-custom">
                   <v-row no-gutters class="fill-height" align="start">
                       <v-col class="translate-column">
                            <router-link to="/contact" class="not-text-decoration">
                                <span class="white--text continuos-texts">Contact</span>
                            </router-link>
                       </v-col>
                   </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>
<script>
export default {
    
}
</script>