import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import './assets/scss/styles.scss'

import VueGtag from "vue-gtag";
import VueMeta from 'vue-meta'


Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(VueGtag, {
  config: { id: "G-SYGMJ5EZ0N" }
});

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')